<template>
  <div class="hello">
    <p><b>{{ message }}</b></p><br>
    <a v-show="btncheck" :href="link"><b>Rejoin</b></a>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  data() {
    return {
      link: "",
      code: "",
      message: "",
      btncheck: 0,
    }
  },
  mounted() {
    let self = this;
    self.link = localStorage.getItem('video_call_link');
    self.message = localStorage.getItem('video_call_message');
    self.code = localStorage.getItem('video_call_code');
    if (self.code == 'WRONG_PASSWORD') {
      self.btncheck = 1;
    } else {
      self.btncheck = 0;
    }
    localStorage.setItem('video_call_link', "");
    localStorage.setItem('video_call_message', "");
    localStorage.setItem('video_call_code', "");
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.hello {
  text-align: center;
  width: 100%;
  padding-right: 200px;
  padding-left: 200px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 200px;
}

.hello p {
  color: #ffffff;
  text-align: center;
}

.hello a {
  color: #1c70ad !important;
  text-align: center;
}
</style>
