<template>
  <div class="white-bg d-flex justify-content-center align-items-center">
    <div class="w-100">
      <b-container class="custom-container py-3">
        <b-row align-h="center">
          <b-col cols="12" md="8" lg="6" align-self="center" class="text-center">
            <b-card class="join-card">
            <div class="logo-image for-leave">
              <img src="../assets/logo-full.svg" alt="" />
            </div>
            <div class="mt-5">
              <h5>Thank you for call.</h5>
              <br>
              <div>
                <b-button v-show="btncheck" variant="outline-primary" class="jon-now-btn" type="submit" @click="clickHandel()">Rejoin</b-button>
              </div>
            </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      code: "",
      message: "",
      btncheck: 0,
      password: "",
    };
  },
  mounted() {
    let self = this;
    self.userId = self.$route.query.id;
    self.token = self.$route.query.token;
    self.room = self.$route.query.room;
    // if (self.userId == "" || self.room == "" || self.room == undefined || self.userId == undefined) {
    //   var message = "Invalid link.";
    //   var code = "INVALID_LINK";
    // }
    self.message = localStorage.getItem('video_call_message');
    self.code = localStorage.getItem('video_call_code');
    if (self.code == 'WRONG_PASSWORD') {
      self.btncheck = 1;
    } else {
      self.btncheck = 1;
    }
  },
  methods:{
    clickHandel() {
      let self = this;
      self.$router.push({path: '/?id=' + self.userId + '&room=' + self.room});
    },
  }
};
</script>
