import Vue from 'vue'
import App from './App.vue'
// We import JQuery
const $ = require('jquery');
// We declare it globally
window.$ = $;
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/styles.css'

import VideoCall from "./components/VideoCall.vue";
import HelloWorld from "./components/HelloWorld.vue";
import JoinVideo from "./components/JoinVideo.vue";
import LeaveVideo from "./components/LeaveVideo.vue";
import VerifiedProfile from "./components/VerifiedProfile.vue";
import VueRouter from "vue-router";

const axios = require('axios');

export const serverBus = new Vue();
 axios.defaults.baseURL = 'https://wire.theresourceful.network/'
//axios.defaults.baseURL = 'http://149.28.145.81/bizlynk-api/public'

const routes = [
    {
        path: "/",
        component: JoinVideo
    },
    {
        path: "/video",
        component: VideoCall
    },
    {
        path: "/thank-you",
        component: LeaveVideo
    },
    {
        path: "/verified",
        component: VerifiedProfile
    }
];
Vue.use(VueRouter);
// Make BootstrapVue available throughout your opportunity
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


Vue.config.productionTip = false
const router = new VueRouter({
    routes
});

new Vue({
    render: h => h(App), router
}).$mount('#app')
