<template>
  <div id="app">
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <router-view></router-view>
  </div>
</template>

<script>
// import VideoCall from './components/VideoCall.vue'

export default {
  name: 'App',
  components: {
    // VideoCall
  }
}
</script>
