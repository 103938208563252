<template>
  <div class="white-bg d-flex justify-content-center align-items-center">
    <div class="w-100">
      <b-container class="custom-container py-3">
        <b-row>
          <b-col cols="12" md="6" align-self="center" class="text-center">
            <div class="logo-image">
              <img src="../assets/logo-full.svg" alt="" />
            </div>
            <h3 class="mt-5">Profile Verification Call</h3>
            <div style="font-size: 12px;">{{datetime}}</div>
          </b-col>
          <b-col cols="12" md="6" align-self="center">
            <b-card class="join-card">
              <b-card-text>
                <h4 class="title-text">Ready to join?</h4>
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-form-group label="Enter Your Password" label-for="password-input" invalid-feedback="Password is required">
                    <b-form-input id="password-input" v-model="password" required></b-form-input>
                  </b-form-group>
                  <span v-show="err_count" style="font-size:12px; color: red;">{{err_message}}</span>
                  <b-form-group class="mt-5">
                <b-button variant="outline-primary" class="jon-now-btn" type="submit">Join Now</b-button>
              </b-form-group>
                </form>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
<b-row>
          <b-col cols="12" align-self="center" class="text-center">

 <b-card-text class="footer"> <b>Note:</b> If you find difficulties joining this video call, we suggest you update your browser to the latest version. Get in touch with us if you still find difficulties joining the call.
</b-card-text>
</b-col>
</b-row>
      </b-container>
    </div>
  </div>
</template>
<style>
.footer {
   position: fixed;
   left: 0;
   bottom: 5px;
   width: 100%;
   background-color: #f26d6d;
   color: white;
   text-align: center;
   line-height: 2.5em;
   font-size:16px;
}
</style>
<script>
import axios from "axios";

export default {
  name: "HelloWorld",
  data() {
    return {
      link: "",
      code: "",
      message: "",
      err_count: "",
      err_message: "",
      datetime: "",
      btncheck: 0,
      password: "",
    };
  },
  created() {
    let self = this;
    localStorage.setItem('password', "");
    localStorage.setItem('video_call_message', "");
    localStorage.setItem('video_call_code', "");
    self.userId = self.$route.query.id;
    self.room = self.$route.query.room;
    if (self.userId == "" || self.room == "" || self.room == undefined || self.userId == undefined) {
      this.$router.push({path:'/thank-you'});
    }
    axios.post('get-room-detail', {room: self.room,uuid:self.userId})
        .then((response) => {
          if(response.data.data)
          {
            var detail = response.data.data;
            if(detail.verified == 0 || detail.verified == '0')
            {
              self.datetime = detail.time;
            } else {
              self.$router.push({path: '/verified'});
            }
          }
        })
        .catch(function () {
        });
  },
  methods: {
    handleSubmit() {
      let self = this;
      localStorage.setItem('password', self.password);
      axios.post('verify-link', {id: self.userId, password: self.password, room: self.room})
        .then((response) => {
          self.err_count = 0;
          self.datetime = response.data.data.datetime;
          self.$router.push({path: '/video?id=' + self.userId + '&room=' + self.room});
        })
        .catch(function (error) {
          self.err_count = 1;
          self.err_message = error.response.data.message;
        });
    },
  }
};
</script>