<template>
  <b-card no-body class="video-call">
    <b-card-body>
      <div class="video-calling">
        <div id="remote-video">
          <div v-for="pa in participants" :key="pa.id" class="user-video remote-user" v-if="checkUserExistInParticipant()">
            <template v-if="pa.user.userId != userId">
            <div class="user-strm-center">
              <div class="user-strm-card">
                <!-- when user muted -->
                <div class="user-muted-btn">
                  <b-button pill size="sm" class="muted-btn" v-if="!pa.isAudioEnabled" disabled>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                      class="bi bi-mic-mute-fill" viewBox="0 0 16 16">
                      <path
                        d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
                      <path
                        d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
                    </svg>
                  </b-button>
                </div>
                  <video :id="pa.isLocalParticipant ? 'local_video_element_id' : pa.id" autoplay="" playsinline
                    class="videochat-stream" v-show="pa.isVideoEnabled ? 1 : 0" />
                  <div class="user-placeholder" v-show="pa.isVideoEnabled ? 0 : 1">
                    <img class="b-avatar-img rounded rounded-circle"
                      :src="pa.user.profileUrl != '' ? pa.user.profileUrl : 'https://cdn3.vectorstock.com/i/thumb-large/32/12/default-avatar-profile-icon-vector-39013212.jpg'"
                      style="height: 75px;width: 75px;">
                    <!--                    <h5 class="user-nickname">{{ pa.user.nickname }}</h5>-->
                    <div class="remote-user-buttons">
                      <b-button-group>

                        <!-- audio on -->
                        <b-button v-if="pa.isAudioEnabled" disabled>
                          <!-- disabled -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-mic-fill" viewBox="0 0 16 16">
                            <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
                            <path
                              d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                          </svg>
                        </b-button>

                        <!-- audio off -->
                        <b-button v-if="!pa.isAudioEnabled" disabled>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-mic-mute-fill" viewBox="0 0 16 16">
                            <path
                              d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
                            <path
                              d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
                          </svg>
                        </b-button>

                        <!-- video off -->
                        <b-button v-if="!pa.isVideoEnabled" disabled>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-camera-video-off-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                              d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925-10-14 .814-.58 10 14-.814.58z" />
                          </svg>
                        </b-button>

                        <!-- video on -->
                        <b-button v-if="pa.isVideoEnabled" disabled>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-camera-video-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                              d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z" />
                          </svg>
                        </b-button>
                      </b-button-group>
                    </div>
                  </div>
                <div class="user-name">{{ pa.user.nickname }}</div>
              </div>
            </div>
            </template>
          </div>
          <div class="user-video user-video-stream">
            <video id="local_video_element_id" autoplay="autoplay" playsinline class="videochat-stream"
              v-show="localParticipant.isVideoEnabled ? 1 : 0" />
            <div class="user-placeholder" v-show="localParticipant.isVideoEnabled ? 0 : 1">
              <img class="b-avatar-img rounded rounded-circle"
                src="https://cdn3.vectorstock.com/i/thumb-large/32/12/default-avatar-profile-icon-vector-39013212.jpg"
                style="height: 75px;width: 75px;">
            </div>
          </div>
        </div>
      </div>
    </b-card-body>

    <template #footer>
      <!-- <video id="local_video_element_id" autoplay="" height="100" width="150"/> -->
      <div class="d-flex justify-content-center">
        <!--        <div class="input-group camera-change" v-if="videoInputs.length==0">-->
        <!--        <div class="input-group camera-change">-->
        <!--          <span class="input-group-text">Camera:</span>-->
        <!--          <select class="form-select" @change="onCameraChange1($event)">-->
        <!--            <option v-for="(vi,index) in videoInputs" :key="vi.deviceId" :value="index">{{ vi.label }}</option>-->
        <!--          </select>-->
        <!--        </div>-->

        <div id="footer-icons">

          <!-- audio on -->
          <b-button pill v-if="localParticipant.isAudioEnabled" @click="muteAudio()">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-mic-fill"
              viewBox="0 0 16 16">
              <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
              <path
                d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
            </svg>
          </b-button>

          <!-- audio off -->
          <b-button pill variant="danger" v-if="!localParticipant.isAudioEnabled" @click="unmuteAudio()">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
              class="bi bi-mic-mute-fill" viewBox="0 0 16 16">
              <path
                d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
              <path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
            </svg>
          </b-button>

          <!-- video off -->
          <b-button pill variant="danger" v-if="!localParticipant.isVideoEnabled"
            @click="unmuteVideo(localParticipant)">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
              class="bi bi-camera-video-off-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925-10-14 .814-.58 10 14-.814.58z" />
            </svg>
          </b-button>

          <!-- audio on -->
          <b-button pill v-if="localParticipant.isVideoEnabled" @click="muteVideo(localParticipant)">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
              class="bi bi-camera-video-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z" />
            </svg>
          </b-button>
          <!-- Flip Cam -->
          <b-button pill @click="onCameraChange($event)" v-if="videoInputs.length > 1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
              class="bi bi-arrow-repeat" viewBox="0 0 16 16">
              <path
                d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
              <path fill-rule="evenodd"
                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
            </svg>
          </b-button>
          <!-- call end -->
          <b-button pill variant="danger" @click="endCall">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
              class="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
            </svg>
          </b-button>
        </div>
      </div>


    </template>

  </b-card>
</template>

<style>
.leave_btn {
  background-image: url('/icons/ic-callkit-end.svg');
  background-color: red;
  /*background-size: 32px;*/
  /*width: 55px;*/
  /*height: 55px;*/
  background-size: 24px 24px;
  padding: 12px;
  border-radius: 2px;
  /*background-color: white;*/
}
</style>

<script>
import SendBirdCall from "sendbird-calls";
// We import JQuery
const $ = require('jquery');
// We declare it globally
window.$ = $;
$('body').css('background-color', '#212242');
const axios = require('axios');

export default {
  name: 'VideoCall',
  props: {
    msg: String
  },
  data() {
    return {
      errorMessage: "",
      memberCount: 0,
      password: '',
      showPassword: true,
      name: '',
      passwordState: null,
      submittedNames: [],
      modalShow: true,
      videoClassName: 'col-12',
      userId: '',// outputs 'yay'
      token: '',// outputs 'yay'
      room: '',// outputs 'yay'
      participants: [],
      localParticipant: {},
      roomObj: {},
      videoInputs: [],
      currentCameraIndex: 0
    }

  },
  async mounted() {
  },
  async created() {

    let self = this;
    // localStorage.setItem("video_call_message", "");
    // localStorage.setItem("video_call_code", "");
    self.userId = self.$route.query.id;
    self.room = self.$route.query.room;
    self.password = localStorage.getItem('password');
    // localStorage.setItem('password', "")
    // if (self.userId == "" || self.room == "" || self.room == undefined || self.userId == undefined) {
    //   var link = 'https://chat.meneja.io/#/?id=' + self.userId + '&room=' + self.room;
    //   var message = "Invalid link.";
    //   var code = "INVALID_LINK";
    //   localStorage.setItem('video_call_link', link);
    //   localStorage.setItem('video_call_message', message);
    //   localStorage.setItem('video_call_code', code);
    //   this.$router.push('/thank-you');
    // }
    this.handleSubmit();

    this.counterInterval = setInterval(
      function () {
        axios
          .post('check-meeting-left-detail', { room: self.room })
          .then((response) => {
            if (response.data.data == 'COMPLETE_MEETING') {
              // this.roomObj.exit();
              // self.$router.push({path: '/thank-you?id=' + self.userId + '&room=' + self.room});
            }
          })
          .catch(function (error) {
          });
      }.bind(this), 100000);

    if (performance.navigation.type == 1) {
      this.removeUser();
    }
    window.addEventListener('beforeunload', this.removeUser)
  },
  beforeDestroy: function () {
    clearInterval(this.counterInterval)
  },
  methods: {
    removeDivIfEmpty() {
      $('div.remote-user:empty').remove();
    },
    checkUserExistInParticipant() {
      let self = this;
      let flag = [];
      $.each(self.participants, function (index, val) {
        if (val.user.userId == self.userId) {
          flag.push(1);
        }else{
          flag.push(0);
        }
      });
      if($.inArray(0, flag ) == -1) {
        return false;
      }
      return true;

    },
    onCameraChange1(event) {
      // SendBirdCall.selectVideoInputDevice(this.videoInputs[event.target.value])
    },
    onCameraChange(event) {
      if (this.videoInputs.length > 1) {
        SendBirdCall.selectVideoInputDevice(this.videoInputs[this.currentCameraIndex == 0 ? 1 : 0])
        if (this.currentCameraIndex == 0) {
          this.currentCameraIndex = 1;
        } else {
          this.currentCameraIndex = 0;
        }
      }
    },
    getVideoClass() {
      return this.participants.length > 0 ? 'col-' + (12 / (this.participants.length)) : 'col-12';
    },
    muteAudio() {
      this.localParticipant.muteMicrophone();
    },
    unmuteAudio() {
      this.localParticipant.unmuteMicrophone();
    },
    muteVideo() {
      this.localParticipant.stopVideo();
    },
    unmuteVideo() {
      this.localParticipant.startVideo();
    },
    endCall() {
      this.roomObj.exit();
      this.memberCount = this.getParticipantLength();
      let self = this;
      var message = "Thank you for Call..";
      var code = "END_CALL";
      localStorage.setItem('video_call_message', message);
      localStorage.setItem('video_call_code', code);
      localStorage.setItem('password', "")
      self.$router.push({ path: '/thank-you?id=' + self.userId + '&room=' + self.room });
    },
    removeUser() {
      try {
        this.roomObj.exit();
        this.memberCount = this.getParticipantLength();
      } catch (error) {

      }

    },
    getParticipantLength() {
      let count = 0;
      let self = this;
      $.each(self.participants, function (val) {
        // if (val.user.userId != self.userId) {
        count++;
        // }
      });
      return count;
    },
    handleSubmit() {
      let self = this;
      axios
        .post('verify-link', { id: self.userId, password: self.password, room: self.room })
        .then((response) => {
          self.userId = response.data.data.id;
          self.token = response.data.data.accessToken;
          self.joinCall();
        })
        .catch(function (error) {
          // self.errorMessage = error.response.data.message;
          // var message = error.response.data.message;
          // var code = error.response.data.data.code;
          // localStorage.setItem('video_call_message', message);
          // localStorage.setItem('video_call_code', code);
          // self.$router.push({path: '/thank-you?id=' + self.userId + '&room=' + self.room});
        });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    async joinCall() {
      let self = this;
      SendBirdCall.init('82CC31DE-3F53-43D8-AE19-F06EE84506E7')
      SendBirdCall.useMedia({ audio: true, video: true });
      // The USER_ID below should be unique to your Sendbird application.

      const authOption = { userId: self.userId, accessToken: self.token };
      // const devices = await navigator.mediaDevices.enumerateDevices();

      // (async () => {

      self.videoInputs = await self.getVideoDevices();
      // self.videoInputs = self.getVideoDevices();

      self.currentCamera = 0;
      if (self.videoInputs.length > 0) {
        SendBirdCall.selectVideoInputDevice(self.videoInputs[0]);
      }
      // })();

      SendBirdCall.authenticate(authOption, (result, error) => {
        if (error) {
          alert('We are experiencing difficulties connecting you with our server. Please try again or Contact Us.');
          // Handle authentication failure.
        } else {
          // Establishing websocket connection.
          SendBirdCall.connectWebSocket()
            .then(function () {

              //create a new room

              SendBirdCall.fetchRoomById(self.room)
                .then(room => {

                  // SendBirdCall.selectVideoInputDevice(self.videoInputs[0])
                  // room.exit();
                  const enterParams = {
                    videoEnabled: true,
                    audioEnabled: true
                  }
                  room.enter(enterParams)
                    .then(() => {
                      self.participants = room.remoteParticipants;
                      self.localParticipant = room.localParticipant;
                      self.videoClassName = self.getVideoClass();
                      self.memberCount = self.getParticipantLength();
                      self.roomObj = room;
                      const localMediaView = document.getElementById('local_video_element_id');
                      localMediaView.muted = true;
                      room.localParticipant.setMediaView(localMediaView);
                      // room.localParticipant.startVideo();
                      // room.localParticipant.muteMicrophone();
                      // Called when a remote participant is connected to the media stream and starts sending the media stream.
                      room.on('remoteParticipantStreamStarted', (remoteParticipant) => {
                        let remoteMediaview = document.getElementById(remoteParticipant.participantId);
                        if (remoteParticipant.user.userId != self.userId) {
                          remoteParticipant.setMediaView(remoteMediaview);
                        }
                        self.videoClassName = self.getVideoClass();
                        self.removeDivIfEmpty();
//                        console.log(self.participants);
                      });
                      room.on('remoteParticipantExited', (participant) => {
                        // Called when a remote participant has exited the room.
                        self.participants = room.remoteParticipants;

                        axios
                          .post('participant-detail', { id: self.userId })
                          .then((response) => {
                            if (response.data.data != 'Staff') {
                              // self.$router.push({path: '/thank-you?id=' + self.userId + '&room=' + self.room});
                            }
                          })
                          .catch(function (error) {
                          });

                        // self.localParticipant = room.localParticipant;
                        self.videoClassName = self.getVideoClass();
                        let participantId = participant.participantId;
                        // alert('Participant exited.');
                        $('#' + participantId).parent().remove();
                        self.memberCount = self.getParticipantLength();
                        // let elements = document.getElementsByClassName(participantId);
                        // while (elements.length > 0) {
                        //   elements[0].parentNode.removeChild(elements[0]);
                        // }
                        self.removeDivIfEmpty();
                      });
                      room.on('remoteAudioSettingsChanged', (participant) => {
                        // Called when a remote participant has exited the room.
                        // alert('Participant Audio settings changed.');
                      });
                      room.on('remoteVideoSettingsChanged', (participant) => {
                        // Called when a remote participant has exited the room.
                        // alert('Participant Video settings changed.');
                      });
                      room.on('remoteParticipantEntered', (participant) => {
                        // Called when a remote participant has exited the room.
                        // alert('New Participant arrived');
                        self.participants = room.remoteParticipants;
                        self.videoClassName = self.getVideoClass();
                        self.memberCount = self.getParticipantLength();
                        self.removeDivIfEmpty();
                      });
                      room.on('deleted', () => {
                        // Called when a remote participant has exited the room.
                        self.participants = room.remoteParticipants;
                        self.memberCount = self.getParticipantLength();

                      });
                      room.on('customItemsUpdated', (customitems) => {
                        // Called when a remote participant has exited the room.
                      });
                      room.on('customItemsDeleted', (customitems) => {
                        // Called when a remote participant has exited the room.
                      });
                      room.on('error', (customitems) => {
                        // Called when a remote participant has exited the room.
                      });

                      // User has successfully entered `room`.
                    })
                    .catch(e => {
                      // Handle error.
                    });

                  // `room` with the identifier `ROOM_ID` is fetched from Sendbird Server.
                })
                .catch(e => {
                  // Handle error
                });

            })
            .catch(function () {
              alert('Eroor In Conneciton');
            });
          // The user has been successfully authenticated and is connected to Sendbird server.
        }
      });
    },
    async getVideoDevices() {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      let devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter(device => device.kind === 'videoinput')
    },
    // redirectHome() {
    //   let self = this;
    //   self.$router.push({path: '/thank-you?id=' + self.userId + '&room=' + self.room});
    // }
  }
}

// Establishing websocket connection.
// SendBirdCall.connectWebSocket()
//     .then(/* Succeeded to connect */)
//     .catch(/* Failed to connect */);

</script>
