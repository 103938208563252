<template>
  <div class="white-bg d-flex justify-content-center align-items-center">
    <div class="w-100">
      <b-container class="custom-container py-3">
        <b-row align-h="center">
          <b-col cols="12" md="8" lg="6" align-self="center" class="text-center">
            <b-card class="join-card">
              <div class="logo-image for-leave">
                <img src="../assets/logo-full.svg" alt="" />
              </div>
              <div class="mt-5">
                <h5>Your profile is already verified.</h5>
                <br>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifiedProfile",
  data() {
    return {};
  },
  mounted() {
  },
  methods:{
  }
};
</script>
